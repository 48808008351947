import React from "react";
import { i18n } from "../lib/i18n";
import { Badge, Level, LevelData } from "./level";
import { Intro1Ru } from "./intro_1_ru";
import { ExerciseApp } from "./exercise_app";
import { loadAllLevelNextIndices, loadAllLevelStats } from "../lib/storage";
import { Intro2Ru } from "./intro_2_ru";
import { Intro3Ru } from "./intro_3_ru";
import { Intro4Ru } from "./intro_4_ru";
import { Intro5Ru } from "./intro_5_ru";

// States
const APP_STATE_START = 1;
const APP_STATE_LEVEL_SELECTED = 2;
const APP_STATE_LEVEL_STARTED = 3;

// Levels
const APP_LEVEL_1 = 1;
const APP_LEVEL_2 = 2;
const APP_LEVEL_3 = 3;
const APP_LEVEL_4 = 4;
const APP_LEVEL_5 = 5;

const LEVEL_DATA = [
    new LevelData(APP_LEVEL_1, true, [
        new Badge("Единицы", "3, 5"),
        new Badge("Десятки", "50, 80"),
    ]),
    new LevelData(APP_LEVEL_2, true, [
        new Badge("Двузначные числа", "35, 58"),
        new Badge("Сотни, тысячи, десятки тысяч", "300, 80'000"),
    ]),
    new LevelData(APP_LEVEL_3, true, [
        new Badge("Порядковые числительные до 99", "үшінші, елу сегізінші"),
        new Badge("Трёхзначные числа", "358"),
        new Badge("Двузначные числа тысяч, сотни тысяч", "35'000"),
    ]),
    new LevelData(APP_LEVEL_4, true, [
        new Badge("Четырёхзначные числа", "1'358"),
        new Badge("Трёхзначные числа тысяч", "358'000"),
        new Badge("Кратные миллиону", "358'000'000"),
        new Badge("Склонение числительных", "үште, сегізге")
    ]),
    new LevelData(APP_LEVEL_5, true, [
        new Badge("Числа с больших количеством значащих цифр", "33'555'888"),
        new Badge("Порядковые числительные до 9999", "үш жүз елу сегізінші"),
        new Badge("Десятичные дроби", "0.3"),
    ]),
];

/**
 * props: lang
 */
class QanshaApp extends React.Component {
    constructor(props) {
        super(props);

        this.closeLevel = this.closeLevel.bind(this);
        this.closeLevelWithReload = this.closeLevelWithReload.bind(this);
        this.levelStart = this.levelStart.bind(this);

        this.state = this.loadState();
    }

    makeState(stats, nextIndices) {
        return {
            appState: APP_STATE_START,
            selectedLevel: null,
            stats: stats,
            nextIndices: nextIndices,
        };
    }

    loadState() {
        const stats = loadAllLevelStats(APP_LEVEL_1, APP_LEVEL_5);
        console.log(`Loaded stats for ${stats.length} levels`);
        const nextIndices = loadAllLevelNextIndices(APP_LEVEL_1, APP_LEVEL_5);
        console.log(`Loaded next indices for ${nextIndices.length} levels`);
        return this.makeState(stats, nextIndices);
    }

    i18n(key) {
        return i18n(key, this.props.lang);
    }

    selectLevel(level) {
        console.log(`Selecting level ${level}`);
        this.setState({
            appState: APP_STATE_LEVEL_SELECTED,
            selectedLevel: level,
        });
    }

    levelClick(level) {
        console.log(`Opening level ${level}`);
        this.selectLevel(level);
    }

    closeLevel() {
        console.log("Closing level");
        this.setState({
            appState: APP_STATE_START,
            selectedLevel: null,
        });
    }

    closeLevelWithReload() {
        console.log("Closing level with reload");
        this.setState(this.loadState());
    }

    levelStart(event) {
        event.preventDefault();
        console.log("Starting level");
        this.setState({
            appState: APP_STATE_LEVEL_STARTED,
        });
    }

    renderStartPage() {
        const stats = this.state.stats;
        return (
            <div className="flex flex-col">
                <div className="flex flex-row m-4">
                    <img src="logo.webp" alt="Qansha logo" className="inline-block h-24" />
                    <div className="flex flex-col mx-4">
                        <h1 className="text-6xl lg:text-4xl text-gray-700">{this.i18n("qanshaTitle")}</h1>
                        <p className="text-4xl lg:text-2xl text-gray-500">{this.i18n("qanshaSubtitle")}</p>
                    </div>
                </div>
                <Level lang={this.props.lang} enabled={true} clickCallback={() => {this.levelClick(APP_LEVEL_1)}} stats={stats[0]} data={LEVEL_DATA[0]} />
                <Level lang={this.props.lang} enabled={stats[0] != null && stats[0].completed} clickCallback={() => {this.levelClick(APP_LEVEL_2)}} stats={stats[1]} data={LEVEL_DATA[1]} />
                <Level lang={this.props.lang} enabled={stats[1] != null && stats[1].completed} clickCallback={() => {this.levelClick(APP_LEVEL_3)}} stats={stats[2]} data={LEVEL_DATA[2]} />
                <Level lang={this.props.lang} enabled={stats[2] != null && stats[2].completed} clickCallback={() => {this.levelClick(APP_LEVEL_4)}} stats={stats[3]} data={LEVEL_DATA[3]} />
                <Level lang={this.props.lang} enabled={stats[3] != null && stats[3].completed} clickCallback={() => {this.levelClick(APP_LEVEL_5)}} stats={stats[4]} data={LEVEL_DATA[4]} />
                <div className="flex justify-center my-4">
                    *{this.i18n("prefixBasedOnDataFrom")}&nbsp;
                    <a
                        className="underline text-blue-700"
                        href="https://issai.nu.edu.kz/kz-speech-corpus/">
                        {this.i18n("suffixIssaiDataset")}
                    </a>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const appState = this.state.appState;
        if (appState === APP_STATE_START) {
            return this.renderStartPage();
        } else if (appState === APP_STATE_LEVEL_SELECTED) {
            const selected = this.state.selectedLevel;
            if (selected === APP_LEVEL_1) {
                return (
                    <Intro1Ru closeCallback={this.closeLevel} startCallback={this.levelStart} />
                );
            } else if (selected == APP_LEVEL_2) {
                return (
                    <Intro2Ru closeCallback={this.closeLevel} startCallback={this.levelStart} />
                );
            } else if (selected == APP_LEVEL_3) {
                return (
                    <Intro3Ru closeCallback={this.closeLevel} startCallback={this.levelStart} />
                );
            } else if (selected == APP_LEVEL_4) {
                return (
                    <Intro4Ru closeCallback={this.closeLevel} startCallback={this.levelStart} />
                );
            } else if (selected == APP_LEVEL_5) {
                return (
                    <Intro5Ru closeCallback={this.closeLevel} startCallback={this.levelStart} />
                );
            } else {
                return (
                    <div>The level is under construction</div>
                );
            }
        } else if (appState == APP_STATE_LEVEL_STARTED) {
            const level = this.state.selectedLevel;
            const stats = this.state.stats;
            const nextIndex = this.state.nextIndices[level - 1];
            return (
                <ExerciseApp
                    lang={this.props.lang}
                    level={this.state.selectedLevel}
                    nextIndex={nextIndex}
                    finishCallback={this.closeLevelWithReload}
                />
            );
        }
    }
}

export { QanshaApp };