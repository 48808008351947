
class LevelStats {
    constructor(level, runs, minScore, maxScore, lastScore, completed) {
        if (!(completed == true || completed == false)) {
            throw new Error("completed must be a boolean");
        }
        this.level = level;
        this.runs = runs;
        this.minScore = minScore;
        this.maxScore = maxScore;
        this.lastScore = lastScore;
        this.completed = completed;
    }
    merge(other) {
        if (this.level !== other.level) {
            throw new Error("Cannot merge stats for different levels");
        }
        const runs = this.runs + other.runs;
        const minScore = Math.min(this.minScore, other.minScore);
        const maxScore = Math.max(this.maxScore, other.maxScore);
        const lastScore = other.lastScore;
        const completed = this.completed || other.completed;
        return new LevelStats(this.level, runs, minScore, maxScore, lastScore, completed);
    }
}

function getLevelStatsKey(level) {
    return `v1-level-${level}-stats`;
}

function loadLevelStatsByKey(key) {
    const localStorage = window.localStorage;
    const json = localStorage.getItem(key);
    if (json === null) {
        console.log(`No level stats found for key ${key}`);
        return null;
    }
    const j = JSON.parse(json);
    if (j.level === undefined || j.runs === undefined || j.minScore === undefined || j.maxScore === undefined || j.lastScore === undefined || j.completed === undefined) {
        console.log(`Invalid level stats found for key ${key}`);
        return null;
    }
    return new LevelStats(j.level, j.runs, j.minScore, j.maxScore, j.lastScore, j.completed);
}

function storeLevelStatsByKey(key, stats) {
    const localStorage = window.localStorage;
    console.log(`Storing level stats for level ${stats.level}`);
    localStorage.setItem(key, JSON.stringify(stats));
}

function updateLevelStats(stats) {
    const key = getLevelStatsKey(stats.level);
    const existing = loadLevelStatsByKey(key);
    const merged = existing === null ? stats : existing.merge(stats);
    storeLevelStatsByKey(key, merged);
}

function loadAllLevelStats(minLevel, maxLevel) {
    const stats = [];
    for (let i = minLevel; i <= maxLevel; i++) {
        const key = getLevelStatsKey(i);
        const s = loadLevelStatsByKey(key);
        if (s !== null) {
            stats.push(s);
        } else {
            break;
        }
    }
    while (stats.length < maxLevel - minLevel + 1) {
        stats.push(null);
    }
    return stats;
}

function getLevelNextIndexKey(level) {
    return `v1-level-${level}-next-index`;
}

function storeLevelNextIndex(level, nextIndex) {
    const key = getLevelNextIndexKey(level);
    const localStorage = window.localStorage;
    localStorage.setItem(key, nextIndex.toString());
}

function loadLevelNextIndexByKey(key) {
    const localStorage = window.localStorage;
    const nextIndex = localStorage.getItem(key);
    return parseInt(nextIndex) || 0;
}

function loadAllLevelNextIndices(minLevel, maxLevel) {
    const nextIndices = [];
    for (let i = minLevel; i <= maxLevel; i++) {
        const key = getLevelNextIndexKey(i);
        const nextIndex = loadLevelNextIndexByKey(key);
        nextIndices.push(nextIndex);
    }
    return nextIndices;
}

export {
    LevelStats,
    updateLevelStats,
    loadAllLevelStats,
    storeLevelNextIndex,
    loadAllLevelNextIndices,
};