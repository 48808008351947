import React, { useEffect } from "react";
import { CloseButton } from "./close_button";
import { LabelButton } from "./label_button";
import { render3ColumnTable } from "../lib/table";

const L2_EXAMPLES_2DIGIT = [
    ["11", "10+1", "он бір"],
    ["12", "10+2", "он екі"],
    ["21", "20+1", "жиырма бір"],
];

const L2_EXAMPLES_HUNDREDS = [
    ["100", "100", "жүз"],
    ["100", "1x100", "бір жүз"],
    ["200", "2x100", "екі жүз"],
];

const L2_EXAMPLES_THOUSANDS = [
    ["1000", "1000", "мың"],
    ["1000", "1x1000", "бір мың"],
    ["10000", "10x1000", "он мың"],
];

/**
 *
 * @param {*} props - closeCallback, startCallback
 */
function Intro2Ru(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="flex flex-col lg:max-w-xl">
            <div className="flex justify-between">
                <h1 className="text-6xl lg:text-4xl m-4 text-center text-gray-700">Уровень 2</h1>
                {CloseButton({ onClick: props.closeCallback })}
            </div>
            <p className="text-3xl lg:text-xl m-2 text-gray-500">На этом уровне вам предстоит поработать над двузначными числами и числами, кратными ста и тысяче.</p>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Примеры двузначных чисел</h2>
            <div className="flex justify-center">
                {render3ColumnTable(L2_EXAMPLES_2DIGIT)}
            </div>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Примеры чисел, кратных 100</h2>
            <div className="flex justify-around">
                {render3ColumnTable(L2_EXAMPLES_HUNDREDS)}
            </div>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Примеры чисел, кратных 1000</h2>
            <div className="flex justify-around">
                {render3ColumnTable(L2_EXAMPLES_THOUSANDS)}
            </div>
            <p className="text-3xl lg:text-xl m-4 text-gray-500">
                * Также рекомендуется посмотреть видео на канале «Казахский язык для всех!» на интервале&nbsp;
                <a className="underline text-blue-700" href="https://youtu.be/aAHvvIjF4VQ?si=ZqKKqspnLJ4v_zjj&t=82">
                    1:22&nbsp;-&nbsp;5:06
                </a>
            </p>
            <LabelButton enabled={true} label="Начать" callback={props.startCallback} />
        </div>
    );
}

export { Intro2Ru };