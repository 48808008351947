import React, { useEffect } from "react";
import { CloseButton } from "./close_button";
import { LabelButton } from "./label_button";
import { render2ColumnTable } from "../lib/table";

const L5_EXAMPLES_MB = [
    ["33'555'888", "отыз үш миллион бес жүз елу бес мың сегіз жүз сексен сегіз"],
    ["1'358'000'000", "бір миллиард үш жүз елу сегіз миллион"],
];

const L5_EXAMPLES_ORDINAL = [
    ["1984", "бір мың тоғыз жүз сексен төртінші"],
    ["1984", "мың тоғыз жүз сексен төртінші"],
];

const L5_EXAMPLES_DECIMAL = [
    ["0.3", "нөл бүтін оннан бес"],
    ["5.8", "бес бүтін оннан сегіз"],
    ["5.8", "бес бүтін сегіз"],
];

/**
 *
 * @param {*} props - closeCallback, startCallback
 */
function Intro5Ru(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="flex flex-col lg:max-w-xl">
            <div className="flex justify-between">
                <h1 className="text-6xl lg:text-4xl m-4 text-center text-gray-700">Уровень 5</h1>
                {CloseButton({ onClick: props.closeCallback })}
            </div>
            <p className="text-3xl lg:text-xl m-2 text-gray-500">На этом уровне вам предстоит поработать над числами с большим количеством значащих цифр, большими порядковыми числами и несложными десятичными дробями.</p>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Примеры больших чисел</h2>
            <div className="flex justify-center">
                {render2ColumnTable(L5_EXAMPLES_MB)}
            </div>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Примеры порядковых чисел</h2>
            <div className="flex justify-center">
                {render2ColumnTable(L5_EXAMPLES_ORDINAL)}
            </div>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Примеры десятичных дробей</h2>
            <div className="flex justify-center">
                {render2ColumnTable(L5_EXAMPLES_DECIMAL)}
            </div>
            <p className="text-3xl lg:text-xl m-4 text-gray-500">
                Теорию произношения дробей можно прочитать в статье&nbsp;
                <a className="underline text-blue-700" href="https://www.kaz-tili.kz/chislit06.htm">
                    «Дробные числительные»
                </a>
                &nbsp;на сайте «Казахский язык. Просто о сложном»
            </p>
            <LabelButton enabled={true} label="Начать" callback={props.startCallback} />
        </div>
    );
}

export { Intro5Ru };
