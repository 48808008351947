class Appearance {
    constructor(variants) {
        this.variants = variants;
    }
}

function parseAppearance(appearance) {
    const variants = appearance.variants;
    if (variants == null || variants.length == 0) {
        console.log("parseAppearance: variants is empty");
        return null;
    }
    return new Appearance(variants);
}

class ExerciseTask {
    constructor(record, audioUrl, transcription, appearanceIndex, appearances) {
        this.record = record;
        this.audioUrl = audioUrl;
        this.transcription = transcription;
        this.appearanceIndex = appearanceIndex;
        this.appearances = appearances;
    }
    getExpectedVariants() {
        if (this.appearanceIndex >= this.appearances.length) {
            console.log(`ExerciseTask.getExpectedAnswer: appearanceIndex ${this.appearanceIndex} is out of bounds: ${this.appearances.length}`);
            return null;
        }
        const appearance = this.appearances[this.appearanceIndex];
        const result = appearance.variants;
        if (result.length == 0) {
            console.log("ExerciseTask.getExpectedAnswer: appearance is empty");
            return null;
        }
        return result;
    }
}

function parseTask(numberTask) {
    const record = numberTask.record;
    if (record == null || record.length == 0) {
        console.log("parseTask: record is empty");
        return null;
    }
    const audioUrl = numberTask.audioUrl;
    if (audioUrl == null || !audioUrl.startsWith("http")) {
        console.log(`parseTask: audioUrl is empty or invalid (${audioUrl})`);
        return null;
    }
    const transcription = numberTask.transcription;
    if (transcription == null || transcription.length == 0) {
        console.log("parseTask: transcription is empty");
        return null;
    }
    const appearanceIndex = numberTask.appearanceIndex;
    if (appearanceIndex == null) {
        console.log("parseTask: appearanceIndex is empty");
        return null;
    }
    const rawAppearances = numberTask.appearances;
    if (rawAppearances == null || rawAppearances.length == 0) {
        console.log("parseTask: appearances is empty");
        return null;
    }
    const appearances = rawAppearances.map(parseAppearance);
    for (let i = 0; i < appearances.length; i++) {
        if (appearances[i] == null) {
            console.log(`parseTask: appearance ${i} is empty`);
            return null;
        }
    }
    return new ExerciseTask(record, audioUrl, transcription, appearanceIndex, appearances);
}

function parseTasks(response) {
    const rawTasks = response.tasks;
    if (rawTasks == null || rawTasks.length == 0) {
        console.log("parseTasks: tasks is empty");
        return null;
    }
    const tasks = rawTasks.map(parseTask);
    for (let i = 0; i < tasks.length; i++) {
        if (tasks[i] == null) {
            console.log(`parseTasks: task ${i} is empty`);
            return null;
        }
    }
    return tasks;
}

export {
    parseTasks,
};