import React, { useEffect } from "react";
import { CloseButton } from "./close_button";
import { LabelButton } from "./label_button";
import { render2ColumnTable, render3ColumnTable } from "../lib/table";


const L4_EXAMPLES_4D = [
    ["1358", "бір мың үш жүз елу сегіз"],
    ["1358", "мың үш жүз елу сегіз"],
];

const L4_EXAMPLES_MILLIONS = [
    ["3'000'000", "үш миллион"],
    ["58'000'000", "елу сегіз миллион"],
];

const L4_EXAMPLES_DECLENSION = [
    ["6", "жатыс септік", "алтыда"],
    ["10", "шығыс септік", "оннан"],
    ["1000", "барыс септік", "мыңға"],
];

/**
 *
 * @param {*} props - closeCallback, startCallback
 */
function Intro4Ru(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="flex flex-col lg:max-w-xl">
            <div className="flex justify-between">
                <h1 className="text-6xl lg:text-4xl m-4 text-center text-gray-700">Уровень 4</h1>
                {CloseButton({ onClick: props.closeCallback })}
            </div>
            <p className="text-3xl lg:text-xl m-2 text-gray-500">На этом уровне вам предстоит поработать над четырёхзначными числами и числами, кратным тысяче и миллиону. Также прозвучат примеры склонения числительных.</p>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Примеры четырёхзначных чисел</h2>
            <div className="flex justify-center">
                {render2ColumnTable(L4_EXAMPLES_4D)}
            </div>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Примеры чисел, кратных миллиону</h2>
            <div className="flex justify-center">
                {render2ColumnTable(L4_EXAMPLES_MILLIONS)}
            </div>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Склонение числительных</h2>
            <p className="text-3xl lg:text-xl m-4 text-gray-500">
                Числительные, подобно существительным, могут склоняться по падежам и присоединять падежные окончания.
                Рекомендуется вспомнить&nbsp;
                <a className="underline text-blue-700" href="https://www.kaz-tili.kz/su_krtab.htm">
                    падежи и окончания
                </a>
                &nbsp;на сайте «Казахский язык. Просто о сложном»
            </p>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Примеры склонения</h2>
            <div className="flex justify-center">
                {render3ColumnTable(L4_EXAMPLES_DECLENSION)}
            </div>
            <p className="text-3xl lg:text-xl m-4 text-gray-500">
                * Кроме того, склонение конкретных чисел можно посмотреть, введя их на сайте&nbsp;
                <a
                    className="underline text-blue-700"
                    href="https://kazakhverb.khairulin.com/declension_ru.html?subject=%D0%B0%D0%BB%D1%82%D1%8B">
                    Kazakh Verb
                </a>
            </p>
            <LabelButton enabled={true} label="Начать" callback={props.startCallback} />
        </div>
    );
}

export { Intro4Ru };