import React, { useEffect } from "react";
import { CloseButton } from "./close_button";
import { LabelButton } from "./label_button";
import { render2ColumnTable } from "../lib/table";

const ONES = [
    ["1", "бір"],
    ["2", "екі"],
    ["3", "үш"],
    ["4", "төрт"],
    ["5", "бес"],
    ["6", "алты"],
    ["7", "жеті"],
    ["8", "сегіз"],
    ["9", "тоғыз"],
];

const TENS = [
    ["10", "он"],
    ["20", "жиырма"],
    ["30", "отыз"],
    ["40", "қырық"],
    ["50", "елу"],
    ["60", "алпыс"],
    ["70", "жетпіс"],
    ["80", "сексен"],
    ["90", "тоқсан"],
];

/**
 *
 * @param {*} props - closeCallback, startCallback
 */
function Intro1Ru(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="flex flex-col lg:max-w-xl">
            <div className="flex justify-between">
                <h1 className="text-6xl lg:text-4xl m-4 text-center text-gray-700">Уровень 1</h1>
                {CloseButton({ onClick: props.closeCallback })}
            </div>
            <p className="text-3xl lg:text-xl m-2 text-gray-500">На этом уровне вам предстоит поработать над базовыми знаниями о числах: единицы и десятки</p>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Немного теории</h2>
            <div className="flex justify-around">
                {render2ColumnTable(ONES)}
                {render2ColumnTable(TENS)}
            </div>
            <p className="text-4xl lg:text-2xl m-4 text-gray-500">
                * Подробнее можно почитать в статье&nbsp;
                <a className="underline text-blue-700" href="https://www.kaz-tili.kz/chislit01.htm">
                    «Количественные числительные»
                </a>
                &nbsp;на сайте «Казахский язык. Просто о сложном»
            </p>
            <LabelButton enabled={true} label="Начать" callback={props.startCallback} />
        </div>
    );
}

export { Intro1Ru };