const I18N_LANG_EN = "en";
const I18N_LANG_RU = "ru";
const I18N_LANG_KK = "kk";

var TEXT_TRANSLATIONS = null;

function setEntries(map, key, entries) {
    map.set(key, entries);
}

function setEnRu(map, key, en, ru) {
    setEntries(map, key, new Map([
        [I18N_LANG_EN, en],
        [I18N_LANG_RU, ru],
    ]));
}

function setEnRuKz(map, key, en, ru, kk) {
    setEntries(map, key, new Map([
        [I18N_LANG_EN, en],
        [I18N_LANG_RU, ru],
        [I18N_LANG_KK, kk],
    ]));
}

function prepareQanshaTranslations(map) {
    setEnRu(map, "qanshaTitle",
        "Qansha",
        "Қанша",
    );
    setEnRu(map, "qanshaSubtitle",
        "Listen to recordings and recognize numbers in Kazakh",
        "Слушайте записи и распознавайте числа на казахском",
    );
    setEnRu(map, "prefixBasedOnDataFrom",
        "Based on audio recordings and transcriptions from",
        "Основано на аудиозаписях и транскрипциях",
    );
    setEnRu(map, "suffixIssaiDataset",
        "ISSAI KSC2 dataset",
        "датасета ISSAI KSC2",
    );
    setEnRu(map, "btnStartLevel",
        "Start level",
        "Начать уровень",
    );
    setEnRu(map, "btnInDevelopment",
        "In development",
        "В разработке",
    );
    setEnRu(map, "levelNr",
        (nr) => `Level ${nr}`,
        (nr) => `Уровень ${nr}`,
    );
    setEnRu(map, "levelNrCompleted",
        (nr) => `Level ${nr} completed`,
        (nr) => `Уровень ${nr} пройден`,
    );
    setEnRu(map, "statsRuns",
        "Runs",
        "Запуски",
    );
    setEnRu(map, "scoreStatsTempl",
        (minScore, maxScore, lastScore) => `You were scoring from ${minScore} to ${maxScore}, last score: ${lastScore}`,
        (minScore, maxScore, lastScore) => `Вы набирали от ${minScore} до ${maxScore}, последний счёт: ${lastScore}`,
    );

    setEnRu(map, "levelLoading",
        "Level is loading...",
        "Уровень загружается...",
    );
    setEnRu(map, "levelLoadError",
        "Error loading level",
        "Ошибка загрузки уровня",
    );

    setEnRu(map, "listenAndEnterNumber",
        "Listen to the recording and enter the first number you hear",
        "Прослушайте запись и введите первое число, которое прозвучит"
    );
    setEnRu(map, "browserNotSupportAudio",
        "Your browser does not support the audio element",
        "Ваш браузер не поддерживает аудио элемент",
    );
    setEnRu(map, "hintEnterNumber",
        "Enter number",
        "Введите число",
    );
    setEnRuKz(map, "feedbackCorrect", "Correct", "Верно", "Дұрыс");
    setEnRuKz(map, "feedbackWrong", "Wrong", "Неверно", "Бұрыс");
    setEnRu(map, "feedbackUnknownAnswer", "unknown answer", "ответ не известен");
    setEnRuKz(map, "feedbackWrongAndHereIsCorrect", "Wrong:", "Неверно:", "Бұрыс:");
    setEnRuKz(map, "btnNext",
        "Next",
        "Далее",
        "Келесі",
    );
    setEnRu(map, "btnShowTranscription",
        "Show transcription",
        "Показать транскрипцию",
    );
    setEnRu(map, "roundCleared",
        "Round cleared!",
        "Раунд пройден!",
    );
    setEnRu(map, "youScored",
        "You scored",
        "Вы набрали",
    );
    setEnRu(map, "time",
        "Time",
        "Время",
    );
    setEnRuKz(map, "columnExpected", "Expected", "Ожидалось", "Күтілді");
    setEnRuKz(map, "columnYourAnswers", "Your answers", "Ваши ответы", "Жауаптарыңыз");
    setEnRu(map, "columnScore",
        "Points",
        "Очки",
    );
    setEnRu(map, "btnFinish",
        "Finish",
        "Закончить",
    );
}

function prepareTranslations() {
    const map = new Map();

    prepareQanshaTranslations(map);

    return map;
}

function getTextTranslations() {
    if (TEXT_TRANSLATIONS == null) {
        TEXT_TRANSLATIONS = prepareTranslations();
    }
    return TEXT_TRANSLATIONS;
}

function i18n(key, language) {
    const textTranslations = getTextTranslations();
    if (textTranslations.has(key)) {
        const translations = textTranslations.get(key);
        if (translations.has(language)) {
            return translations.get(language);
        }
        if (translations.has(I18N_LANG_EN)) {
            return translations.get(I18N_LANG_EN);
        }
    }
    return `<TRANSLATION_NOT_FOUND: ${key}>`;
}

export {
    I18N_LANG_EN,
    I18N_LANG_RU,
    I18N_LANG_KK,
    i18n,
};