import React from "react";
import { i18n as globalI18n } from "../lib/i18n";

class Badge {
    constructor(text, examples) {
        this.text = text;
        this.examples = examples;
    }
}

class LevelData {
    constructor(nr, available, badges) {
        this.nr = nr;
        this.available = available;
        this.badges = badges;
    }
}

/**
 *
 * @param {*} props lang, enabled, clickCallback, stats, data
 */
function Level(props) {

    function i18n(key) {
        return globalI18n(key, props.lang);
    }

    function renderLevelTitle() {
        if (props.stats != null && props.stats.completed) {
            return (
                <span className="text-2xl lg:text-base text-green-500">{i18n("levelNrCompleted")(props.data.nr)}</span>
            );
        } else {
            return (
                <span className="text-gray-600">{i18n("levelNr")(props.data.nr)}</span>
            );
        }
    }

    function renderStatsRuns() {
        if (props.stats == null) {
            return <span></span>;
        }
        return (
            <span className="text-2xl lg:text-base ml-6">
                {i18n("statsRuns")}:&nbsp;<strong>{props.stats.runs}</strong>
            </span>
        );
    }

    function renderScoreStats() {
        if (props.stats == null) {
            return null;
        }
        return (
            <div className="text-right text-2xl lg:text-base my-1">
                {i18n("scoreStatsTempl")(props.stats.minScore, props.stats.maxScore, props.stats.lastScore)}
            </div>
        );
    }

    let badgeItems = [];
    for (const badge of props.data.badges) {
        badgeItems.push(
            <div
                key={badgeItems.length}
                className="flex flex-col w-56 border-1 border-gray-500 bg-gray-200 m-2 p-2 rounded text-2xl lg:text-xl">
                <p className="text-center">{badge.text}</p>
                <i className="mt-2 text-center">{badge.examples}</i>
            </div>
        );
    }

    function renderButton() {
        if (!props.data.available) {
            return (
                <button
                    className="bg-gray-500 text-white text-5xl lg:text-4xl m-4 font-bold px-4 rounded focus:outline-none focus:shadow-outline inline-flex justify-center">
                    <img src="under_constr.svg" alt="Under Construction" />
                    {i18n("btnInDevelopment")}
                </button>
            );
        } else if (!props.enabled) {
            return (
                <button
                    className="bg-gray-500 text-white text-5xl lg:text-4xl m-4 font-bold px-4 rounded focus:outline-none focus:shadow-outline">
                    {i18n("btnStartLevel")}
                </button>
            );
        } else {
            return (
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white text-5xl lg:text-4xl m-4 font-bold px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={props.clickCallback}>
                    {i18n("btnStartLevel")}
                </button>
            );
        }
    }

    return (
        <div className="border-2 border-gray-500 m-4 p-4">
            <div className="flex flex-col">
                <div className="flex flex-row justify-between text-xl">
                    {renderLevelTitle()}
                    {renderStatsRuns()}
                </div>
                {renderScoreStats()}
                <div className="flex flex-wrap justify-around">
                    {badgeItems}
                </div>
                {renderButton()}
            </div>
        </div>
    );
}

export {
    Badge,
    LevelData,
    Level,
};