import React from 'react';
import { render } from 'react-dom';
import { I18N_LANG_RU } from './lib/i18n';
import { QanshaApp } from './components/qansha_app';


const root = document.getElementById("qansha_root");

render(
    <QanshaApp lang={I18N_LANG_RU} />,
    root
);