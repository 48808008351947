import React from "react";

export function render2ColumnTable(entries) {
    return (
        <table className="text-center text-3xl lg:text-xl m-4">
            <tbody>
                {entries.map(([num, word]) => (
                    <tr key={num}>
                        <td className="px-6 py-2 border-2">{num}</td>
                        <td className="px-4 py-2 border-2 text-blue-700">{word}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export function render3ColumnTable(entries) {
    return (
        <table className="text-center text-3xl lg:text-xl m-4">
            <tbody>
                {entries.map(([x, y, z]) => (
                    <tr key={x}>
                        <td className="px-6 py-2 border-2">{x}</td>
                        <td className="px-6 py-2 border-2">{y}</td>
                        <td className="px-4 py-2 border-2 text-blue-700">{z}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}