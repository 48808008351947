import React, { useEffect } from "react";
import { CloseButton } from "./close_button";
import { LabelButton } from "./label_button";
import { render2ColumnTable, render3ColumnTable } from "../lib/table";

const L3_ORDINALS_1_9 = [
    ["1", "первый", "бірінші"],
    ["2", "второй", "екінші"],
    ["3", "третий", "үшінші"],
    ["4", "четвёртый", "төртінші"],
    ["5", "пятый", "бесінші"],
    ["6", "шестой", "алтыншы"],
    ["7", "седьмой", "жетінші"],
    ["8", "восьмой", "сегізінші"],
    ["9", "девятый", "тоғызыншы"],
];

const L3_ORDINALS_10_100 = [
    ["10", "десятый", "оныншы"],
    ["20", "двадцатый", "жиырмасыншы"],
    ["30", "тридцатый", "отызыншы"],
    ["40", "сороковой", "қырқыншы"],
    ["50", "пятидесятый", "елуінші"],
    ["60", "шестидесятый", "алпысыншы"],
    ["70", "семидесятый", "жетпісінші"],
    ["80", "восьмидесятый", "сексенінші"],
    ["90", "девяностый", "тоқсаныншы"],
    ["100", "сотый", "жүзінші"],
];

const L3_EXAMPLES_ORD_COMBO = [
    ["11", "одиннадцатый", "он бірінші"],
    ["58", "пятьдесят восьмой", "елу сегізінші"],
];

const L3_EXAMPLES = [
    ["123", "бір жүз жиырма үш"],
    ["123", "жүз жиырма үш"],
    ["583000", "бес жүз сексен үш мың"],
];

/**
 *
 * @param {*} props - closeCallback, startCallback
 */
function Intro3Ru(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="flex flex-col lg:max-w-xl">
            <div className="flex justify-between">
                <h1 className="text-6xl lg:text-4xl m-4 text-center text-gray-700">Уровень 3</h1>
                {CloseButton({ onClick: props.closeCallback })}
            </div>
            <p className="text-3xl lg:text-xl m-2 text-gray-500">На этом уровне вам предстоит поработать над порядковыми числительными, а также трёхзначными числами и числами кратным 1000.</p>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Порядковые числительные от 1 до 9</h2>
            <div className="flex justify-center">
                {render3ColumnTable(L3_ORDINALS_1_9)}
            </div>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Порядковые числительные от 10 до 100</h2>
            <div className="flex justify-center">
                {render3ColumnTable(L3_ORDINALS_10_100)}
            </div>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Составные порядковые</h2>
            <div className="flex justify-center">
                {render3ColumnTable(L3_EXAMPLES_ORD_COMBO)}
            </div>
            <p className="text-3xl lg:text-xl m-4 text-gray-500">
                * Также рекомендуется посмотреть видео на канале «Казахский язык для всех!» на интервале&nbsp;
                <a className="underline text-blue-700" href="https://youtu.be/aAHvvIjF4VQ?si=ZUb5y-4Wou-NmG1o&t=400">
                    6:40&nbsp;-&nbsp;7:32
                </a>
            </p>
            <h2 className="text-4xl lg:text-2xl m-4 text-center">Примеры трёхзначных и кратных 1000</h2>
            <div className="flex justify-center">
                {render2ColumnTable(L3_EXAMPLES)}
            </div>
            <LabelButton enabled={true} label="Начать" callback={props.startCallback} />
        </div>
    );
}

export { Intro3Ru };