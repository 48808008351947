import React from "react";

/**
 *
 * @param {*} props - callback, enabled, label
 * @returns
 */
export function LabelButton(props) {
    const btnClass = props.enabled ? "bg-blue-500 hover:bg-blue-700" : "bg-gray-500";
    const callback = props.enabled ? props.callback : null;

    return (
        <button
            className={`${btnClass} text-white text-5xl lg:text-4xl m-4 font-bold lg:max-w-xl px-4 rounded focus:outline-none focus:shadow-outline`}
            onClick={callback}>
            {props.label}
        </button>
    );
}