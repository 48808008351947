function makeGenericApiRequest(method, url, params, successCallback, errorCallback, context, responseIsJson) {
    if (method !== "GET" && method !== "POST") {
        throw "unsupported http method " + method;
    }
    let headers = {};
    if (method == "POST") {
       headers["Content-Type"] = "application/json";
    }
    let fetchParams = {
        method: method,
        headers: headers,
    };
    if (method == "POST") {
        fetchParams["body"] = JSON.stringify(params);
    }
    fetch(url, fetchParams)
        .then((response) => {
            if (response.ok) {
                if (responseIsJson) {
                    successCallback(context, response.json());
                } else {
                    successCallback(context, response.text());
                }
            } else {
                errorCallback(context, response.text());
            }
        });
}

function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}

function executeGetJsonRequest(path, cgiParams, successCallback, errorCallback, context) {
    const query = encodeQueryData(cgiParams);
    const url = `${path}?${query}`;
    makeGenericApiRequest("GET", url, null, successCallback, errorCallback, context, true);
}

export {
    executeGetJsonRequest,
};