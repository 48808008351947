import React from "react";
import { i18n } from "../lib/i18n";
import { executeGetJsonRequest } from "../lib/backend";
import { parseTasks } from "../lib/exercise_task";
import { LevelStats, storeLevelNextIndex, updateLevelStats } from "../lib/storage";
import { CloseButton } from "./close_button";

const SCORE_CORRECT = 10;
const SCORE_INCORRECT = 0;

const SPEECH_ICONS = [
    "speech_play.webp",
    "speech_pos1.webp",
    "speech_pos2.webp",
    "speech_pos3.webp",
];
let speechPos = 2;

class ExerciseTaskResult {
    constructor(answer, score) {
        this.answer = answer;
        this.score = score;
    }
}

class ExerciseApp extends React.Component {
    /**
     * props:
     * - lang
     * - level
     * - nextIndex
     * - finishCallback
     */
    constructor(props) {
        super(props);

        this.onTasksReceived = this.onTasksReceived.bind(this);
        this.onTasksError = this.onTasksError.bind(this);
        this.advanceAnimation = this.advanceAnimation.bind(this);
        this.onSpeechClick = this.onSpeechClick.bind(this);
        this.onSpeechPlay = this.onSpeechPlay.bind(this);
        this.onSpeechPause = this.onSpeechPause.bind(this);
        this.onSpeechEnd = this.onSpeechEnd.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onShowTranscription = this.onShowTranscription.bind(this);
        this.onNext = this.onNext.bind(this);
        this.completeRun = this.completeRun.bind(this);

        this.state = this.defaultState();

        this.requestTasks(props.level, props.nextIndex);
    }

    i18n(key) {
        return i18n(key, this.props.lang);
    }

    makeState(tasks, progress) {
        return {
            tasks: tasks,
            loadError: null,
            nextIndex: 0,
            progress: progress,
            lastEntered: "",
            playing: false,
            animationTimer: null,
            correct: null,
            shownTranscription: -1,
            startDate: null,
            finishDate: null,
        };
    }

    defaultState() {
        return this.makeState(
            /* tasks */ null,
            /* progress */ [],
        );
    }

    requestTasks(level, nextIndex) {
        const path = "/api/v1/get_round";
        const cgiParams = { level, nextIndex };
        console.log(`Requesting tasks for level ${level}, nextIndex ${nextIndex}`);
        executeGetJsonRequest(path, cgiParams, this.onTasksReceived, this.onTasksError, {});
    }

    async onTasksReceived(context, responseJsonPromise) {
        const response = await responseJsonPromise;
        console.log("Parsing tasks")
        const tasks = parseTasks(response);
        console.log(`Parsed ${tasks.length} tasks`);
        const nextIndex = response.nextIndex;
        if (nextIndex == null || nextIndex == undefined || nextIndex < 0) {
            console.log(`Invalid nextIndex: ${nextIndex}`);
            return;
        }
        const startDate = new Date();
        this.setState({ tasks, nextIndex, startDate });
    }

    async onTasksError(context, responseTextPromise) {
        const responseText = await responseTextPromise;
        console.log(`Tasks request failed: ${responseText}`);
        const tasks = null;
        const loadError = this.i18n("levelLoadError");
        this.setState({ tasks, loadError });
    }

    renderTask(taskIndex, total) {
        const task = this.state.tasks[taskIndex];

        const correct = this.state.correct;
        const enabled = correct == null;
        const inputExtraClass = (
            enabled
            ? ""
            : (
                correct
                ? " border-green-400"
                : " border-red-400"
            )
        );
        const inputClass = `shadow appearance-none border-2 rounded w-full m-4 p-2 text-6xl lg:text-4xl text-gray-700 text-center focus:outline-none focus:shadow-outline${inputExtraClass}`;
        const buttonEnabled = enabled && this.state.lastEntered.length > 0;
        const buttonExtraClass = buttonEnabled ? "bg-blue-500 hover:bg-blue-700" : "bg-gray-500";
        const buttonClass = `text-white text-6xl lg:text-4xl my-4 font-bold px-4 rounded focus:outline-none focus:shadow-outline ${buttonExtraClass}`;
        return (
            <div className="px-3 py-2 flex flex-col">
                <div className="flex justify-between">
                    <h1 className="text-5xl lg:text-4xl m-4 text-center text-gray-700">{taskIndex + 1} / {total}</h1>
                    {CloseButton({ onClick: this.props.finishCallback })}
                </div>
                <h2 className="text-5xl lg:text-3xl m-2 lg:max-w-2xl text-center text-gray-600">{this.i18n("listenAndEnterNumber")}</h2>
                <div className="flex flex-row m-4 justify-center">
                    <audio
                        controls
                        autoPlay
                        onPlay={this.onSpeechPlay}
                        onPause={this.onSpeechPause}
                        onEnd={this.onSpeechEnd}
                        className="invisible h-0 w-0" ref="audio">
                            <source src={task.audioUrl} type="audio/mpeg" />
                            {this.i18n("browserNotSupportAudio")}
                    </audio>
                    <img
                        ref="speechButton"
                        src={this.state.playing ? SPEECH_ICONS[speechPos] : SPEECH_ICONS[0]}
                        className="h-28"
                        onClick={this.onSpeechClick}
                        alt="speech button" />
                </div>
                <form onSubmit={this.onSubmit} className="flex">
                    <input
                        type="text"
                        size="20"
                        maxLength="32"
                        readOnly={enabled ? null : "readOnly"}
                        value={this.state.lastEntered}
                        onChange={this.onChange}
                        placeholder={this.i18n("hintEnterNumber")}
                        className={inputClass}
                        autoFocus />
                    <button
                        type="submit"
                        className={buttonClass}>
                        →
                    </button>
                </form>
                {this.renderFeedback(task, correct)}
                {this.renderNextButton(correct)}
                <div className="flex justify-center my-6">
                    {this.renderTranscriptionSection(taskIndex, correct)}
                </div>
            </div>
        )
    }

    startAnimation() {
        const animationTimer = setInterval(
            this.advanceAnimation,
            600,
        );
        const playing = true;
        this.setState({ animationTimer, playing });
        console.log("Animation started");
    }

    stopAnimation() {
        const animationTimer = this.state.animationTimer;
        if (animationTimer != null) {
            clearInterval(animationTimer);
        }
        this.setState({ animationTimer: null, playing: false });
        console.log("Animation stopped");
    }

    advanceAnimation() {
        const nextSpeechPos = speechPos < 3 ? (speechPos + 1) : 1;
        speechPos = nextSpeechPos;
        const speechButton = this.refs.speechButton;
        speechButton.src = SPEECH_ICONS[speechPos];
    }

    onSpeechClick(event) {
        console.log("Speech click");

        const playing = !this.state.playing;
        const audio = this.refs.audio;
        if (audio == null) {
            console.log("No audio element");
            return;
        }
        if (playing) {
            console.log("Playing audio");
            audio.play();
        } else {
            console.log("Pausing audio");
            audio.pause();
        }
    }

    onSpeechPlay(event) {
        console.log("Speech play");
        this.startAnimation();
    }

    onSpeechPause(event) {
        console.log("Speech pause");
        this.stopAnimation();
    }

    onSpeechEnd(event) {
        console.log("Speech end");
        this.stopAnimation();
    }

    onChange(event) {
        const lastEntered = event.target.value;
        this.setState({ lastEntered });
    }

    onSubmit(event) {
        event.preventDefault();
        console.log("Submit");
        const lastEntered = this.state.lastEntered.trim().replace(/,/g, ".")
        if (lastEntered.length == 0) {
            return;
        }
        if (this.state.correct != null) {
            console.log("Already have a result");
            return;
        }
        const taskIndex = this.state.progress.length;
        const task = this.state.tasks[taskIndex];
        const variants = task.getExpectedVariants();
        if (variants == null) {
            console.log("No expected variants");
            return;
        }
        let correct = variants.indexOf(lastEntered) >= 0;
        storeLevelNextIndex(this.props.level, this.props.nextIndex + taskIndex + 1);
        this.setState({ correct });
    }

    renderFeedback(task, correct) {
        if (correct == null) {
            return <p className="m-4 p-2 text-5xl lg:text-4xl invisible">n/a</p>;
        } else if (correct == true) {
            return <p className="m-4 p-2 text-5xl lg:text-4xl text-center text-green-400">{this.i18n("feedbackCorrect")}</p>;
        } else {
            const variants = task.getExpectedVariants();
            const expected = variants.length > 0 ? variants[0] : this.i18n("feedbackUnknownAnswer");
            return (
                <p className="m-4 p-2 text-5xl lg:text-4xl text-center text-red-400">
                    {this.i18n("feedbackWrongAndHereIsCorrect")}&nbsp;<strong>{expected}</strong>
                </p>
            );
        }
    }

    renderButton(key, onClick) {
        return (
            <button
                className="text-white text-6xl lg:text-4xl my-4 font-bold px-4 rounded focus:outline-none focus:shadow-outline bg-blue-500 hover:bg-blue-700"
                onClick={onClick}>
                {this.i18n(key)}
            </button>
        );
    }

    renderNextButton(correct) {
        if (correct == null) {
            return null;
        }
        return this.renderButton("btnNext", this.onNext);
    }

    renderTranscriptionSection(taskIndex, correct) {
        if (correct == null) {
            return null;
        }
        const shown = this.state.shownTranscription;
        if (shown != taskIndex) {
            return (
                <button
                    onClick={this.onShowTranscription}
                    className="border-b-2 border-dotted border-blue-400 italic text-blue-400 text-3xl lg:text-xl">
                    {this.i18n("btnShowTranscription")}
                </button>
            );
        } else {
            return (
                <p
                    className="text-3xl lg:text-xl italic text-center max-w-2xl text-gray-600">
                    {this.state.tasks[taskIndex].transcription}
                </p>
            );
        }
    }

    onShowTranscription(e) {
        e.preventDefault();
        const shownTranscription = this.state.progress.length;
        this.setState({ shownTranscription });
    }

    completeRun() {
        const progress = this.state.progress;
        if (progress.length < this.state.tasks.length) {
            return;
        }
        console.log("completeRun: Saving level stats");
        let totalScore = 0;
        for (let i = 0; i < progress.length; i++) {
            totalScore += progress[i].score;
        }
        const levelStats = new LevelStats(
            this.props.level,
            1,
            totalScore,
            totalScore,
            totalScore,
            totalScore == progress.length * SCORE_CORRECT,
        );
        updateLevelStats(levelStats);
        storeLevelNextIndex(this.props.level, this.state.nextIndex);
    }

    onNext(event) {
        event.preventDefault();
        console.log("Next");
        const curCorrect = this.state.correct;
        if (curCorrect == null) {
            return;
        }
        this.stopAnimation();
        const result = new ExerciseTaskResult(
            this.state.lastEntered,
            curCorrect ? SCORE_CORRECT : SCORE_INCORRECT,
        );
        const progress = this.state.progress.concat([result]);
        const lastEntered = "";
        const correct = null;
        const finishDate = progress.length == this.state.tasks.length ? new Date() : null;
        this.setState(
            { progress, lastEntered, correct, finishDate },
            () => {
                window.scrollTo(0, 0);
                this.completeRun();

                const audio = this.refs.audio;
                if (audio) {
                    audio.pause();
                    audio.load();
                    audio.play();
                }
            }
        );
    }

    renderFinished(tasks, total) {
        if (total != this.state.progress.length) {
            throw new Error(`Got ${this.state.progress.length} results for ${total} tasks`);
        }
        let totalScore = 0;
        let tableRows = [];
        for (let i = 0; i < total; i++) {
            const task = tasks[i];
            const taskResult = this.state.progress[i];
            totalScore += taskResult.score;
            const scoreClass = taskResult.score > 0 ? "text-green-400" : "null";
            const scoreText = taskResult.score > 0 ? `+${taskResult.score}` : taskResult.score;

            const row = (
                <tr
                    className="border-t-2 text-2xl"
                    key={i}>
                    <td>{i + 1}</td>
                    <td>{task.getExpectedVariants().join(", ")}</td>
                    <td>{taskResult.answer}</td>
                    <td className={scoreClass}>{scoreText}</td>
                </tr>
            );
            tableRows.push(row);
        }
        const maxScore = total * SCORE_CORRECT;
        const scoreString = `${totalScore} / ${maxScore}`;
        return (
            <div className="flex flex-col">
                <h1 className="text-3xl lg:text-xl text-center text-gray-600 mt-4">{this.i18n("levelNr")(this.props.level)}</h1>
                <h2 className="text-5xl lg:text-3xl text-center text-gray-600 m-4">{this.i18n("roundCleared")}</h2>
                <table className="table-auto text-center my-10">
                    <thead>
                        <tr className="border-t-2 text-4xl lg:text-2xl">
                            <th className="px-4">#</th>
                            <th className="px-4">{this.i18n("columnExpected")}</th>
                            <th className="px-4">{this.i18n("columnYourAnswers")}</th>
                            <th className="px-4">{this.i18n("columnScore")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
                <p className="text-4xl lg:text-2xl text-center m-4">{this.i18n("youScored")}&nbsp;<strong>{scoreString}</strong></p>
                <p className="text-4xl lg:text-2xl text-center m-4 text-gray-600">{this.i18n("time")}&nbsp;<strong>{this.calcTime()}</strong></p>
                {this.renderButton("btnFinish", this.props.finishCallback)}
            </div>
        );
    }

    calcTime() {
        const startDate = this.state.startDate;
        const finishDate = this.state.finishDate;
        if (startDate == null || finishDate == null) {
            console.log("calcTime: Missing start or finish date");
            return null;
        }
        const diff = finishDate - startDate;
        const seconds = Math.floor((diff + 500) / 1000);
        const minutes = Math.floor(seconds / 60);
        const secondsPart = seconds % 60;
        return `${minutes < 10 ? "0" : ""}${minutes}:${secondsPart < 10 ? "0" : ""}${secondsPart}`;
    }

    render() {
        const loadError = this.state.loadError;
        if (loadError != null) {
            return <p className="text-4xl lg:text-2xl text-red-600 m-4">{loadError}</p>;
        }
        const tasks = this.state.tasks;
        if (tasks == null) {
            return <p className="text-4xl lg:text-2xl text-gray-600 m-4">{this.i18n("levelLoading")}</p>;
        }
        const taskIndex = this.state.progress.length;
        const total = tasks.length;
        if (taskIndex < total) {
            return this.renderTask(taskIndex, total);
        }
        return this.renderFinished(tasks, total);
    }
}

export {
    ExerciseApp,
}